@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap");
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* font-family: "Inter", sans-serif; */
  font-family: "Manrope", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@layer components {
  .btn-primary {
    @apply px-5 mt-2 py-1.5 text-sm bg-primary text-white font-[500] rounded-lg border-[1px] border-primary;
  }

  .btn-secondary {
    @apply px-5 mt-2 py-1.5 text-sm text-gray-500 font-[500] rounded-lg border-[1px] border-gray-400;
  }

  .title {
    @apply text-2xl font-[600] text-[#101828] ease-in duration-300;
  }
  .subtitle {
    @apply text-[#6B7280];
  }
}
