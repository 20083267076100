/* src/App.css */
.App {
  text-align: center;
  padding-left: 12px;
  padding-right: 12px;
  min-height: 500px;
  min-width: 300px;
}

.loading-spinner {
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #76f99b;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Chrome, Safari, Edge, Opera */
.no-spinner::-webkit-outer-spin-button,
.no-spinner::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.no-spinner[type="number"] {
  -moz-appearance: textfield;
}

/* input {
  margin-right: 10px;
}

.product-details {
  margin-top: 10px;
} */
