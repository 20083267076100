.row {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
}

.box {
    flex: 1;
    border: 0px;
    padding: 10px;
    margin-right: 10px;
}

.box:last-child {
    margin-right: 0;
}

input[type='text'] {
    width: 100%;
    padding: 5px;
    border-radius: 5px;
}

/* .sidebar {
    position: fixed;
    top: 0;
    right: -290px;
    width: 290px;
    height: 100%;
    background-color: blue;
    transition: right 0.3s ease; 
    }

    .sidebar.open {
    right: 0; 
  } */


.google-signin-button {
    display: flex;
    min-width: 225px;
    align-items: center;
    color: #4285F4;
    background-color: white;
    border: none;
    border-radius: 4px;
    padding: 10px 24px;
    font-size: 16px;
    cursor: pointer;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    transition: background-color 0.3s;
}

.google-signin-button:hover {
    background-color: #357AE8;
    color: white;
}

/* Icon styles */
.google-signin-button img {
    width: 18px;
    height: 18px;
    margin-right: 10px;
}

.apple-signin-button {
    min-width: 225px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    background-color: #fff;
    color: #000;
    border: none;
    border-radius: 4px;
    padding: 10px 24px;
    font-size: 16px;
    cursor: pointer;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    transition: background-color 0.3s;
}

.apple-signin-button img {
    width: 18px;
    height: 19px;
    margin-right: 10px;
}

.apple-signin-button:hover {
    background-color: #333;
    color: white;
}